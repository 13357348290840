import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRevalidator,
  useRouteError,
} from "@remix-run/react";
import "./tailwind.css";
import { createBrowserClient } from "@supabase/ssr";
import { json } from "@remix-run/node";
import { Resource } from "sst/resource";
import { useEffect, useState } from "react";
import { createSupabaseServerClient } from "./supabase.server";

export async function loader({ request }: { request: Request }) {
  const env = {
    supabaseUrl: Resource.SupabaseUrl.value,
    supabaseKey: Resource.SupabaseKey.value,
    appBaseUrl: Resource.AppBaseUrl.value,
  };

  const { supabase, headers } = createSupabaseServerClient(request);

  const {
    data: { session },
  } = await supabase.auth.getSession();

  return json({ env, session }, { headers });
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  const { env, session } = useLoaderData<typeof loader>();
  const { revalidate } = useRevalidator();
  const [supabase] = useState(() =>
    createBrowserClient(env.supabaseUrl, env.supabaseKey)
  );
  const serverAccessToken = session?.access_token;

  useEffect(() => {
    // Make env available globally
    window.env = env;

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session?.access_token !== serverAccessToken) {
        // server and client are out of sync.
        revalidate();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [serverAccessToken, supabase, revalidate, env]);

  return <Outlet context={{ supabase }} />;
}
